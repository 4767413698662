import { Trans } from "react-i18next";
import { PASSWORD_MIN_LENGTH } from "../../helpers/constants";
import { translate as t } from "../../helpers/i18n";
import usePasswordValidation from "../../hooks/use-password-validation";

export interface PasswordChecklistProps {
  password: string;
}

const PasswordChecklist = ({ password }: PasswordChecklistProps) => {
  const {
    hasLength,
    hasAtLeast3Conditions,
    hasLowercase,
    hasUpperCase,
    hasNumber,
    hasSpecialChar,
  } = usePasswordValidation(password);

  const handlePasswordRequirementsClasses = (condition: boolean) =>
    `${
      condition
        ? "text-notification-success-bold"
        : "text-typography-surface-high-emphasis"
    } mb-0`;

  return (
    <div className="col-12 border border-primary-06 rounded p-12px bg-white">
      <p className="mb-0 text-typography-surface-high-emphasis">
        {t("password_validation.password_checklist.contain")}
      </p>
      <p className={handlePasswordRequirementsClasses(hasLength)}>
        <Trans
          i18nKey="password_validation.password_checklist.min_length"
          values={{
            minLength: PASSWORD_MIN_LENGTH,
            check: t("password_validation.password_checklist.check", {
              count: +hasLength,
            }),
          }}
        />
      </p>
      <p className={handlePasswordRequirementsClasses(hasAtLeast3Conditions)}>
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.at_least"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasAtLeast3Conditions,
            }),
          }}
        />
      </p>
      <p className={`${handlePasswordRequirementsClasses(hasLowercase)} ms-3`}>
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.lower_cases"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasLowercase,
            }),
          }}
        />
      </p>
      <p className={`${handlePasswordRequirementsClasses(hasUpperCase)} ms-3`}>
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.upper_cases"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasUpperCase,
            }),
          }}
        />
      </p>
      <p className={`${handlePasswordRequirementsClasses(hasNumber)} ms-3`}>
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.numbers"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasNumber,
            }),
          }}
        />
      </p>
      <p
        className={`${handlePasswordRequirementsClasses(hasSpecialChar)} ms-3`}
      >
        <Trans
          i18nKey="password_validation.password_checklist.extra_checklist.special_chars"
          values={{
            check: t("password_validation.password_checklist.check", {
              count: +hasSpecialChar,
            }),
          }}
        />
      </p>
    </div>
  );
};

export default PasswordChecklist;
