import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { Statsig } from "statsig-react";
import { EXPERIMENTS, useExperimentsCriteriaPreLaunch } from "./use-ab-test";
import { useMortgageValues } from "./use-mortgage-values";
import { RootState } from "../stores";
import { useIsUserAuthenticated } from "./use-is-user-authenticated";

// Custom hook to manage experiment analytics
const useExperimentAnalytics = () => {
  const isUserAuthenticated = useIsUserAuthenticated();

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const AUTH_EXPERIMENT_SESSION_VALUE = "_exp_mxp_grp_";
  const UNAUTH_EXPERIMENT_SESSION_VALUE = "_unauth_exp_mxp_grp_";

  // Extracting necessary data from redux
  const { user: userData } = useSelector((state: RootState) => state.user);
  const { isLoading: isLoadingMortgage, isIdle: mortgageNotInitialized } =
    useMortgageValues();

  const tavantDitialHeloanCriteria = useExperimentsCriteriaPreLaunch(
    EXPERIMENTS.TAVANT_DIGITAL_HELOAN
  );

  const tavantSSOCriteria = useExperimentsCriteriaPreLaunch(
    EXPERIMENTS.TAVANT_SSO
  );

  // User identification and authentication checks
  const userID = userData?.id ?? "";
  const unAuthSessionID = localStorage.getItem("session_id");

  // Function to evaluate an experiment for the current user
  const evaluateExperiment = useCallback(
    async (
      experimentName: string,
      meetsExperimentCriteria: boolean,
      isUnauthenticatedExperiment: boolean = false
    ) => {
      // pre-launch
      if (!meetsExperimentCriteria) return;

      try {
        const id = isUnauthenticatedExperiment ? unAuthSessionID : userID;
        const sessionKey = `${id}${
          isUnauthenticatedExperiment
            ? UNAUTH_EXPERIMENT_SESSION_VALUE
            : AUTH_EXPERIMENT_SESSION_VALUE
        }${experimentName}`;
        const experimentGroup = sessionStorage.getItem(sessionKey);
        if (experimentGroup) return; // Exit if this experiment has already been evaluated

        const config =
          Statsig.getExperimentWithExposureLoggingDisabled(experimentName);

        const isExperimentActive = config.getIsExperimentActive();
        const groupName = config.getGroupName();

        if (!isExperimentActive) return; // Exit if the experiment is not active

        const isExperimentLaunched = !!groupName && !isExperimentActive;
        if (isExperimentLaunched) return; // Exit if the experiment is launched

        // The groupName and sessionStorage are for the purpose of analytics and
        // to avoid redundant user log submissions to Statsig
        const groupNameValue =
          config.getGroupName() === "Control" ? "Control" : "Test";

        sessionStorage.setItem(sessionKey, groupNameValue); // Store the experiment group in sessionStorage
      } catch (e) {
        console.error(e);
      }
    },
    [userID, unAuthSessionID]
  );

  // Initialize experiments based on user status and authentication
  useEffect(() => {
    const initialization = async () => {
      if (
        isUserAuthenticated &&
        !isLoadingMortgage &&
        !mortgageNotInitialized
      ) {
        await Promise.all([
          evaluateExperiment(EXPERIMENTS.TAVANT_SSO, tavantSSOCriteria),
          evaluateExperiment(
            EXPERIMENTS.TAVANT_DIGITAL_HELOAN,
            tavantDitialHeloanCriteria
          ),
        ]);
      }

      setIsInitialized(true); // Mark initialization as complete
    };
    initialization();
  }, [
    isUserAuthenticated,
    evaluateExperiment,
    isInitialized,
    isLoadingMortgage,
    mortgageNotInitialized,
    tavantSSOCriteria,
    tavantDitialHeloanCriteria,
  ]);

  // Register experiments in Mixpanel once initialization is complete
  useEffect(() => {
    const mixpanelInitialization = async (
      isAuth: boolean = true,
      id: string
    ) => {
      const experimentsTest: string[] = [];
      const experimentsControl: string[] = [];
      const allExperiments: string[] = [];
      const unAuthExperimentsTest: string[] = [];
      const unAuthExperimentsControl: string[] = [];
      const allUnauthExperiments: string[] = [];
      Object.keys(sessionStorage).forEach((key) => {
        if (
          key.startsWith(
            `${id}${
              isAuth
                ? AUTH_EXPERIMENT_SESSION_VALUE
                : UNAUTH_EXPERIMENT_SESSION_VALUE
            }`
          )
        ) {
          const experimentName = key.split(
            `${id}${
              isAuth
                ? AUTH_EXPERIMENT_SESSION_VALUE
                : UNAUTH_EXPERIMENT_SESSION_VALUE
            }`
          )[1];
          // Add to the general list of experiments
          if (isAuth) {
            allExperiments.push(experimentName);
          } else {
            allUnauthExperiments.push(experimentName);
          }
          const group = sessionStorage.getItem(key);
          if (group === "Test") {
            if (isAuth) {
              experimentsTest.push(experimentName);
            } else {
              unAuthExperimentsTest.push(experimentName);
            }
          } else if (group === "Control") {
            if (isAuth) {
              experimentsControl.push(experimentName);
            } else {
              unAuthExperimentsControl.push(experimentName);
            }
          }
        }
      });
      // We need to use `await` with mixpanel because failing to do so results in asynchrony
      // in the `mixpanel.register()` for events while mixpanel is still initializing
      await mixpanel;
      // Register the experiments with Mixpanel
      if (isAuth) {
        mixpanel.register({
          experiments_test: experimentsTest,
          experiments_control: experimentsControl,
          experiments: allExperiments, // Send the complete list of experiments
        });
      } else {
        mixpanel.register({
          unauth_experiments_test: unAuthExperimentsTest,
          unauth_experiments_control: unAuthExperimentsControl,
          unauth_experiments: allUnauthExperiments, // Send the complete list of experiments
        });
      }
    };
    //auth users
    if (
      isInitialized &&
      isUserAuthenticated &&
      !isLoadingMortgage &&
      !mortgageNotInitialized &&
      userID
    ) {
      mixpanelInitialization(true, userID);
    }
    // unauth users
    if (!isUserAuthenticated) {
      mixpanelInitialization(false, unAuthSessionID || "");
    }
  }, [
    unAuthSessionID,
    isInitialized,
    isUserAuthenticated,
    userID,
    isLoadingMortgage,
    mortgageNotInitialized,
  ]);
};

export default useExperimentAnalytics;
