import { ReactNode, useEffect, useMemo, useState } from "react";
import { StatsigProvider, StatsigUser } from "statsig-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./stores";
import Loader from "./components/loader";
import useFetchUserData from "./hooks/use-fetch-user-data";
import useGlobalErrors from "./hooks/use-global-errors";
import { useIsUserAuthenticated } from "./hooks/use-is-user-authenticated";
import { getOrCreateSessionId } from "analytics";
import { loadAccounts } from "./stores/account-slice";
import { useSession } from "./hooks/use-session";

const STATSIG_PROVIDER_PROPS = {
  sdkKey: process.env.REACT_APP_STATSIG_CLIENT_API_KEY || "",
  waitForInitialization: true,
  shutdownOnUnmount: true,
  options: {
    // setting enviroment
    environment: { tier: process.env.REACT_APP_STATSIG_ENVIROMENT_TIER },
    // in local mode it will not send the metrics
    localMode: !!process.env.REACT_APP_STATSIG_LOCAL_MODE,
  },
};

interface Props {
  children: ReactNode;
}

const AppInitializer = ({ children }: Props) => {
  /**
   * Hooks that do not require the app (redux or statsig) to be initialized first are called below
   */

  // Global fetch of user data
  useFetchUserData();

  // Track global errors.
  useGlobalErrors();

  // Initialize session handling
  useSession();

  const isUserAuthenticated = useIsUserAuthenticated();
  const [isStatsigInitialized, setIsStatsigInitialized] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const {
    user: { user: userData, status },
    account: { status: accountStatus },
  } = useSelector((state: RootState) => state);

  const statsigUser: StatsigUser = useMemo(() => {
    const unAuthSessionID = getOrCreateSessionId();

    return {
      userID: isUserAuthenticated ? userData?.id : unAuthSessionID,
      custom: {
        isUserAuthenticated,
      },
    };
  }, [isUserAuthenticated, userData?.id]);

  // Load account data only when the user is authenticated and account status is idle
  useEffect(() => {
    if (status === "authenticated" && accountStatus === "idle") {
      dispatch(loadAccounts());
    }
  }, [dispatch, status, accountStatus]);

  // show loader until auth user data an account information are fully loaded
  if (
    status === "loading" ||
    (status === "authenticated" &&
      (accountStatus === "loading" || accountStatus === "idle"))
  ) {
    return <Loader />;
  }

  const isUserDetected =
    status === "authenticated" || status === "unauthenticated";
  const shouldRenderApp = isStatsigInitialized && isUserDetected;

  return (
    <StatsigProvider
      {...STATSIG_PROVIDER_PROPS}
      options={{
        ...STATSIG_PROVIDER_PROPS.options,
        initCompletionCallback: () => setIsStatsigInitialized(true),
      }}
      user={statsigUser}
    >
      {shouldRenderApp ? children : <Loader />}
    </StatsigProvider>
  );
};

export default AppInitializer;
