import BaseModal, { BaseModalProps } from "./index";
import React from "react";
import ModalLoadingMessage from "./components/modal-loading-message";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../stores";
import { clearModal, closeModal } from "../../../stores/modal-slice";
import { cx } from "class-variance-authority";

export interface GenericBaseModalProps extends BaseModalProps {
  isLoading?: boolean;
  loadingMessage?: string;
}

const GenericBaseModal = ({
  isLoading,
  loadingMessage,
  ...props
}: GenericBaseModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleOnExited = () => {
    dispatch(clearModal());
  };

  return (
    <BaseModal
      contentClassName="generic-modal-content w-100 d-flex flex-column gap-4 p-s16 p-md-s24"
      titleClassName="flex-grow-1 mb-0 me-s16 typeface-h3 text-blue-900"
      bodyClassName="p-0 typeface-body-regular text-blue-900"
      footerClassName="w-100 d-flex gap-0 justify-content-start p-0 border-0 rounded-0 shadow-none bg-transparent"
      onCloseButtonClick={
        props.onCloseButtonClick
          ? props.onCloseButtonClick
          : () => dispatch(closeModal())
      }
      onEscapeKeyDown={() => dispatch(closeModal())}
      {...props}
      headerClassName={cx(
        props.headerClassName ||
          "w-100 p-0 d-flex align-items-start justify-content-between",
        props.title ?? "flex-row-reverse"
      )}
      buttons={
        isLoading ? (
          <ModalLoadingMessage loadingMessage={loadingMessage} />
        ) : (
          props.buttons
        )
      }
      onExited={handleOnExited}
    />
  );
};

export default GenericBaseModal;
