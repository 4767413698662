import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { ReactComponent as UserSVG } from "purple-rain/icons/user-circle.svg";
import { pages } from "../../../helpers/pages";
import { translate as t } from "../../../helpers/i18n";
import { RootState } from "../../../stores";
import { HamburgerMenu, ProductsMenu } from "./header-menus";
import { AuthenticatedButton, HeaderLoginLink } from "./header-auth";
import useStandaloneMode from "../../../hooks/use-standalone";
import { ReactComponent as StandaloneLogoLakeviewSVG } from "purple-rain/assets/standalone-logo-lakeview.svg";
import { useDashboardVersion } from "../../../hooks";
import { ReactComponent as LogoLakeviewUnauthenticatedRebrandSVG } from "purple-rain/assets/logo-lakeview-unauthenticated-rebrand.svg";
import { Theme, useThemeContext } from "../../../contexts/theme-context";

type Props = {
  displayAuthInfo: boolean;
  showProfileLink?: boolean;
};

const iconLakeviewLogo = (theme: string) => {
  const iconByTheme = {
    [Theme.PURPLE_RAIN]: (
      <StandaloneLogoLakeviewSVG
        width={170}
        height="100%"
        className="fastlane-svg-logo"
      />
    ),
    [Theme.LAKEVIEW_BLUE]: (
      <LogoLakeviewUnauthenticatedRebrandSVG width={110} height="100%" />
    ),
  };

  return iconByTheme[theme] || iconByTheme[Theme.PURPLE_RAIN];
};

const signInButton = (theme: string) => {
  const buttonByTheme = {
    [Theme.PURPLE_RAIN]: <HeaderLoginLink className="py-3" />,
    [Theme.LAKEVIEW_BLUE]: <></>,
  };

  return buttonByTheme[theme] || buttonByTheme[Theme.PURPLE_RAIN];
};

const Header = ({ displayAuthInfo, showProfileLink = true }: Props) => {
  const user = useSelector((state: RootState) => state.user.user);
  const shouldRenderStandalone = useStandaloneMode();
  const { mixpanelPageName } = useDashboardVersion();
  const { theme } = useThemeContext();

  const handleLogoClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "header",
      button: "logo",
      page: mixpanelPageName,
    });
  };

  if (shouldRenderStandalone)
    return (
      <header className="pt-2 pb-3 py-md-5 bg-gradient">
        <div className="container d-flex flex-column align-items-center gap-3">
          <StandaloneLogoLakeviewSVG />
          <div className="text-black text-center">
            {t("layout.header.standalone")}
          </div>
        </div>
      </header>
    );

  return (
    <header>
      <nav className="navbar navbar-light">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex">
              <HamburgerMenu />
              <Link
                className="navbar-brand p-0"
                to={user ? pages.home : pages.index}
                aria-label={t("layout.header.links.label")}
                onClick={handleLogoClick}
              >
                {iconLakeviewLogo(theme)}
                <span className="visually-hidden">
                  {t("accessibility.go_home_button")}
                </span>
              </Link>
            </div>
            <div className="flex-fill">
              <ProductsMenu />
            </div>
            <div className="position-relative d-flex align-items-center flex-shrink-0">
              {user && displayAuthInfo ? (
                <>
                  {showProfileLink && (
                    <Link
                      to={pages.profile}
                      className="text-black text-decoration-none me-0 me-md-2 me-lg-3 d-flex align-items-center"
                      data-testid="nav-bar-view-profile-btn"
                    >
                      <UserSVG
                        width={24}
                        height={24}
                        className="me-0 me-md-2"
                      />
                      <span className="d-none d-lg-inline">
                        {t("layout.header.user_greeting", {
                          name: user?.first_name,
                        })}
                      </span>
                      <span className="visually-hidden">
                        {t("accessibility.go_profile_button")}
                      </span>
                    </Link>
                  )}
                  <div className="d-none d-md-inline">
                    <AuthenticatedButton />
                  </div>
                </>
              ) : (
                signInButton(theme)
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
