import { cx } from "class-variance-authority";
import mixpanel, { Dict } from "mixpanel-browser";
import { useDispatch, useSelector } from "react-redux";
import { translate as t } from "../helpers/i18n";
import { AppDispatch, RootState } from "../stores";
import {
  getSubservicerLink,
  RedirectTargetType,
} from "../stores/subservicer-slice";
import ConfirmLeaveModal from "./confirm-leave-modal";
import { Link } from "react-router-dom";
import { MIXPANEL_EVENTS } from "analytics";
import { ReactComponent as ExternalLinkSVG } from "purple-rain/icons/external-link.svg";
import {
  getMortgageAssistanceLink,
  getSubservicerName,
  getSubservicerShortName,
  isMrCooper,
  REDIRECT_TARGET,
} from "../helpers/subservicer";
import { FEATURE_GATES, useFeatureGate } from "../hooks/use-feature-gate";
import { Button, ButtonProps } from "../atomic/atoms/button";

interface SubservicerLinkProps {
  className?: string;
  onClick?: () => void;
  translationKey: string;
  module: string;
  isMortgageAssistance?: boolean;
  isLinkTag?: boolean;
  isConfirmationModal?: boolean;
  hasIcon?: boolean;
  subservicerLoanNumber?: string;
  isButton?: boolean;
  buttonProps?: ButtonProps;
  redirectTarget?: string;
  eventParams?: Dict;
}

/**
 *
 * Builds a link to the subservicer using the stored account information
 * and tracks the link click event whenever the link is clicked
 *
 * @param {string} [className] - classname to customize the link (in case it needs to be a button)
 * @param {Function} [onClick] - Function to be called when the link is clicked
 * @param {string} translationKey - translation key to display in the link,
 *                         the key can use a "subservicerName" param if needed
 *                         if the translation doesn't need the subservicerName it won't affect the text
 * @param {string} module - which module is serving the link, mainly used for tracking
 * @param {boolean} [isMortgageAssistance=false] - Determines where the link points
 *  * @param {boolean} [isConfirmationModal=true] - Show or not the confirmation modal to open the link
 * @param {boolean} [hasIcon=false] - Shows the external svg icon
 * @param isLinkTag
 * @param isConfirmationModal
 * @param {string} subservicerLoanNumber - Fastlane internal loan UUID. Used for analytics purposes
 * @param {Function} [subservicerLoanNumber] - Function to be called when the link has loaded
 * @param isButton
 * @param buttonProps
 * @param redirectTarget
 * @param {Dict} [eventParams] - Params used in Mixpanel events
 */
export const SubservicerLink = ({
  className,
  translationKey,
  module,
  isMortgageAssistance = false,
  hasIcon = false,
  isLinkTag = true,
  isConfirmationModal = true,
  subservicerLoanNumber = "",
  isButton,
  buttonProps,
  redirectTarget = REDIRECT_TARGET.OVERVIEW,
  eventParams,
}: SubservicerLinkProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { user: userState, subservicer: subservicerState } = useSelector(
    (state: RootState) => state
  );
  const isSubservicerRedirectParamsEnabled = useFeatureGate(
    FEATURE_GATES.ENABLE_SUBSERVICER_REDIRECT_TARGET_PARAMS
  );

  // Subservicer information
  const subservicerName = getSubservicerName(userState.user);
  const mortgageAssistanceLink = getMortgageAssistanceLink(userState.user);
  const isMrCooperSubservicer = isMrCooper(userState.user);

  /**
   * Handler to execute with the modal opens
   *
   * We avoid fetching the link:
   *   * When the subservicer link is for mortgage assistance
   */
  const handleShowModal = () => {
    if (!isMortgageAssistance) {
      const params =
        isSubservicerRedirectParamsEnabled && isMrCooperSubservicer
          ? { redirect_target: redirectTarget as RedirectTargetType }
          : {};
      dispatch(getSubservicerLink(params));
    }
  };

  const subservicerLink =
    isMortgageAssistance && mortgageAssistanceLink
      ? mortgageAssistanceLink
      : subservicerState?.subservicer?.link;

  // Styling - unless customized we'll default to link styling
  const linkStyle = isLinkTag
    ? `${className || ""} text-decoration-underline link-offset-3 text-link`
    : className;

  const subservicerShortName = getSubservicerShortName(userState.user);
  const button = isMortgageAssistance
    ? "learn-more"
    : `proceed-to-${subservicerShortName}`;

  const handleSubservicerLinkClick = () => {
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      button,
      module,
      view_source: "leave-dialog",
      subservicer_loan_number: subservicerLoanNumber,
    });

    subservicerLink && window.open(subservicerLink, "_blank");
  };

  if (isConfirmationModal) {
    const buttonText = t(translationKey, {
      subservicerName,
      interpolation: { escapeValue: false },
    });

    const buttonIcon = hasIcon && <ExternalLinkSVG width={24} height={24} />;

    const currentEventParams = eventParams ? eventParams : { button };

    return (
      <ConfirmLeaveModal
        modalType="subservicer_link"
        isLoading={subservicerState.status === "loading"}
        loadingMessage={t("leaving_modal.global.loading_link")}
        onShowModal={handleShowModal}
        href={subservicerLink}
        viewSource={module}
        eventParams={currentEventParams}
      >
        {isButton ? (
          <Button {...buttonProps}>
            {buttonIcon}
            {buttonText}
          </Button>
        ) : (
          <span
            role="button"
            className={cx(linkStyle, hasIcon && "d-flex align-items-center")}
          >
            {buttonIcon}
            {buttonText}
          </span>
        )}
      </ConfirmLeaveModal>
    );
  }

  const disabledColorStyle = !subservicerLink && "text-muted-blue-450 pe-none";

  return (
    <Link
      className={cx(className, disabledColorStyle)}
      to="#"
      onClick={handleSubservicerLinkClick}
    >
      {t(translationKey, {
        subservicerName,
        interpolation: { escapeValue: false },
      })}
    </Link>
  );
};
