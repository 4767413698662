import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translate as t } from "../../../helpers/i18n";
import { generateId } from "../../../helpers/utils";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { useDashboardVersion } from "../../../hooks";
import { AppDispatch, RootState } from "../../../stores";
import {
  displayFirstTimeBanner,
  displayPasswordSetup,
  hideSSOBanner,
  StepType,
} from "../../../stores/sso-user-slice";
import BenefitsBox from "./benefits-box";
import BannerContainer from "./banner-container";
import PasswordBox from "./password-box";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { getSubservicerName } from "../../../helpers/subservicer";
import { Button } from "../../atoms/button";
import TermsAndConditionsModal from "../terms-and-conditions-modal";

const initialPasswordFormId = generateId();

export const stepBoxContent = (
  stepType: StepType,
  key: string,
  handleDismiss: () => void,
  openTermsModal: (password: string) => void
) => {
  const content: Record<StepType, ReactNode> = {
    introduction: <BenefitsBox />,
    password_setup: (
      <PasswordBox
        key={key}
        onDismiss={handleDismiss!}
        openTermsModal={openTermsModal}
      />
    ),
  };

  return content[stepType];
};

export const stepExtraContent = (
  stepType: StepType,
  handleClick: () => void
) => {
  const content: Record<StepType, ReactNode> = {
    introduction: (
      <Button onClick={handleClick}>{t("sso_banner.introduction.cta")}</Button>
    ),
    password_setup: null,
  };

  return content[stepType];
};

const SSOBanner = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { pathname } = useLocation();
  const previousLocation = useRef<string>();

  const {
    hasDismissedFirstTimeBanner,
    canShowSSOFirstTimeBanner,
    dismissFirstTimeBanner,
    confirmedPassword,
    openTermsModal,
  } = useSSOValues();

  const {
    ssoUser: { showSSOBanner, isFirstTimeBanner, currentStep },
    user: { user: userData },
  } = useSelector((state: RootState) => state);

  const { mixpanelPageName } = useDashboardVersion();

  const subservicerName = useMemo(
    () => getSubservicerName(userData),
    [userData]
  );

  const [passwordFormKey, setPasswordFormKey] = useState(initialPasswordFormId);

  /**
   * Displays the banner if the user loads and conditions apply
   */
  useEffect(() => {
    if (canShowSSOFirstTimeBanner) {
      dispatch(displayFirstTimeBanner());
    }
  }, [dispatch, canShowSSOFirstTimeBanner]);

  /**
   * Track when the banner is displayed
   */
  useEffect(() => {
    if (showSSOBanner && subservicerName) {
      const step = currentStep === "introduction" ? 1 : 2;
      // Track event
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "sso-hero",
        step,
        subservicerName,
      });
    }
  }, [showSSOBanner, currentStep, subservicerName]);

  /**
   * Banner behavior when user navigates
   * * If user navigates away and banner is open - hide the banner
   * * If the user navigates to home and the first time banner should display - display first time banner
   */
  useEffect(() => {
    if (!!previousLocation.current && pathname !== previousLocation.current) {
      canShowSSOFirstTimeBanner
        ? dispatch(displayFirstTimeBanner())
        : showSSOBanner && dispatch(hideSSOBanner());
    }
    previousLocation.current = pathname;
  }, [dispatch, showSSOBanner, canShowSSOFirstTimeBanner, pathname]);

  // reset scroll to display form at the top
  const handleDisplayPasswordSetup = () => {
    // Track click and display events
    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "sso-hero",
      step: 1,
      button: "unlock-dashboard",
      page: mixpanelPageName,
    });

    dispatch(displayPasswordSetup(true));
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  };

  const handleDismissBanner = () => {
    let firstDismiss = false;

    dispatch(hideSSOBanner());

    // We regenerate the key in the password form
    // to reset the component and erase its state
    // when the banner is dismissed
    setPasswordFormKey(generateId());

    if (!hasDismissedFirstTimeBanner) {
      firstDismiss = true;
      dismissFirstTimeBanner();
    }

    mixpanel.track(MIXPANEL_EVENTS.CLICK, {
      module: "sso-hero",
      step: 2,
      button: "maybe-later",
      page: mixpanelPageName,
      firstDismiss,
    });
  };

  const boxContent = stepBoxContent(
    currentStep,
    passwordFormKey,
    handleDismissBanner,
    openTermsModal
  );
  const extraContent = stepExtraContent(
    currentStep,
    handleDisplayPasswordSetup
  );

  return (
    <>
      <TermsAndConditionsModal
        password={confirmedPassword}
        isUncompletedProfile
      />
      <BannerContainer
        show={showSSOBanner}
        showSteps={isFirstTimeBanner}
        step={currentStep}
        boxContent={boxContent}
        extraContent={extraContent}
      />
    </>
  );
};

export default SSOBanner;
