import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericBaseModalProps } from "../atomic/organisms/modal/generic-base-modal";

export const GenericModalInitialConfig: Partial<GenericBaseModalProps> = {
  show: false,
  dialogClassName: "",
  contentClassName:
    "generic-modal-content w-100 d-flex flex-column gap-4 p-s16 p-md-s24",
  headerClassName: "w-100 p-0 d-flex align-items-start justify-content-between",
  titleClassName: "flex-grow-1 mb-0 me-s16 typeface-h3 text-blue-900",
  bodyClassName: "p-0 typeface-body-regular text-blue-900",
  footerClassName:
    "w-100 d-flex gap-0 justify-content-start p-0 border-0 rounded-0 shadow-none bg-transparent",
  isLoading: false,
  centered: true,
  scrollable: true,
  keyboard: true,
  title: undefined,
  buttons: null,
  children: null,
  onCloseButtonClick: undefined,
  onClose: undefined,
  onHide: undefined,
};

// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
// Modal Slice (State)
// - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -  - -|
const initialState: GenericBaseModalProps = {
  ...GenericModalInitialConfig,
};

export const modalSlice = createSlice({
  name: "modalState",
  initialState,
  reducers: {
    buildModal(state, action: PayloadAction<GenericBaseModalProps>) {
      Object.assign(state, action.payload);
      state.show = true;
    },
    updateModal(state, action: PayloadAction<GenericBaseModalProps>) {
      Object.assign(state, action.payload);
    },
    clearModal(state) {
      Object.assign(state, initialState);
    },
    openModal(state) {
      state.show = true;
    },
    closeModal(state) {
      state.show = false;
    },
    toggleModal(state) {
      state.show = !state.show;
    },
  },
});

export const {
  buildModal,
  updateModal,
  clearModal,
  openModal,
  closeModal,
  toggleModal,
} = modalSlice.actions;
