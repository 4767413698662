import { t } from "i18next";
import { useState } from "react";
import PasswordChecklist from "../../atoms/password-checklist-component";
import PasswordInput from "../../../components/password-input";
import { escapeRegExp, PASSWORD_MIN_LENGTH } from "../../../helpers/constants";

export interface PasswordValidationGroupProps {
  password: string;
  handlePassword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  isDarkBackground?: boolean;
  passwordPlaceholder?: string;
}

const PasswordValidationGroup = ({
  password,
  handlePassword,
  className = "",
  isDarkBackground = false,
  passwordPlaceholder = t("password_validation.new_password"),
}: PasswordValidationGroupProps) => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className={`gap-3 d-flex flex-column ${className}`}>
        <div className="form-floating w-100">
          <PasswordInput
            password={password}
            handlePassword={handlePassword}
            placeholder={passwordPlaceholder}
            id="password"
            label={passwordPlaceholder}
            invalidFeedback={t("password_validation.validation.new_password")}
            invalidFeedbackClassName={isDarkBackground ? "text-white" : ""}
            minLength={PASSWORD_MIN_LENGTH}
            isPasswordInput
          />
        </div>
        <div className="form-floating w-100">
          <PasswordInput
            password={confirmPassword}
            handlePassword={handleConfirmPassword}
            placeholder={t("password_validation.confirm_password")}
            label={t("password_validation.confirm_password")}
            id="confirm-password"
            invalidFeedback={t(
              "password_validation.validation.confirm_password"
            )}
            pattern={escapeRegExp(password)}
            invalidFeedbackClassName={isDarkBackground ? "text-white" : ""}
          />
        </div>
      </div>
      {password && <PasswordChecklist password={password} />}
    </div>
  );
};

export default PasswordValidationGroup;
