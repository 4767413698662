import { Suspense, useEffect, useState } from "react";
import {
  getTermsVersions,
  isMajorVersionDifferent,
  lazyWithRefresh,
} from "../../../helpers/utils";
import { AFBA_VERSION } from "../../../helpers/constants";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "../../../stores";
import { useSSOValues } from "../../../hooks/use-sso-values";
import { updateUIState } from "../../../stores/app-slice";

const TermsAndConditionsModal = lazyWithRefresh(
  () => import("../../../atomic/organisms/terms-and-conditions-modal"),
  "terms-and-conditions-modal"
);

const InitialTermsConditions = () => {
  const [showAcceptAfBAModal, setShowAcceptAfBAModal] =
    useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.user);
  const { showSSOBanner } = useSelector((state: RootState) => state.ssoUser);
  const { showTavantBanner } = useSelector((state: RootState) => state.tavant);
  const { isPendingFastlaneAccount, hasDismissedAfBAModal } = useSSOValues();
  const { userTermsVersion, latestTermsVersion } = getTermsVersions(user);

  const showTermsAndConditionsModal =
    !isPendingFastlaneAccount &&
    user &&
    isMajorVersionDifferent(latestTermsVersion, userTermsVersion);

  useEffect(() => {
    if (
      user &&
      user?.disclaimer_acceptances?.AFBA?.version &&
      isMajorVersionDifferent(
        AFBA_VERSION,
        user?.disclaimer_acceptances?.AFBA?.version
      ) &&
      !hasDismissedAfBAModal &&
      !showTermsAndConditionsModal &&
      !showSSOBanner &&
      !showTavantBanner
    ) {
      setShowAcceptAfBAModal(true);
    } else {
      setShowAcceptAfBAModal(false);
    }
  }, [
    user,
    user?.disclaimer_acceptances?.AFBA?.version,
    hasDismissedAfBAModal,
    showSSOBanner,
    showTermsAndConditionsModal,
    showTavantBanner,
  ]);

  useEffect(() => {
    dispatch(
      updateUIState({
        isContentBlockOverlay:
          showAcceptAfBAModal ||
          showSSOBanner ||
          showTermsAndConditionsModal ||
          showTavantBanner,
      })
    );
  }, [
    showAcceptAfBAModal,
    showSSOBanner,
    showTavantBanner,
    showTermsAndConditionsModal,
  ]);

  return (
    <>
      {showTermsAndConditionsModal && (
        <Suspense fallback={null}>
          <TermsAndConditionsModal isUpdatedTermsAndConditions />
        </Suspense>
      )}
      {showAcceptAfBAModal && !showTermsAndConditionsModal && (
        <Suspense fallback={null}>
          <TermsAndConditionsModal showOnlyAfBA />
        </Suspense>
      )}
    </>
  );
};

export default InitialTermsConditions;
