import { translate as t } from "../../../helpers/i18n";
import AfBAContent from "../afba/afba-content";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "analytics";
import { useEffect } from "react";
import GenericBaseModal from "../modal/generic-base-modal";
import ModalButtonsWrapper from "../modal/components/modal-buttons-wrapper";
import { Button } from "../../atoms/button";

export interface AcceptAfBAModalProps {
  show: boolean;
  onCancel?: () => void;
  onAccept?: () => void;
  isLoading?: boolean;
}

const AcceptAfBAModal = ({
  show,
  onCancel,
  onAccept,
  isLoading,
}: AcceptAfBAModalProps) => {
  useEffect(() => {
    if (show) {
      mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
        module: "afba-conditions",
      });
    }
  }, [show]);

  const handleOnAccept = () => {
    if (typeof onAccept === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: "afba-conditions",
        button: "agree",
      });
      onAccept();
    }
  };

  const handleOnCancel = () => {
    if (typeof onCancel === "function") {
      mixpanel.track(MIXPANEL_EVENTS.CLICK, {
        module: "afba-conditions",
        button: "maybe later",
      });
      onCancel();
    }
  };
  return (
    <GenericBaseModal
      show={show}
      title={
        <>
          <span className="d-md-none">{t("afba_modal.mobile_title")}</span>
          <span className="d-none d-md-block">
            {t("afba_modal.desktop_title")}
          </span>
        </>
      }
      onClose={handleOnCancel}
      onCloseButtonClick={handleOnCancel}
      fullscreen="md-down"
      isLoading={isLoading}
      buttons={
        <ModalButtonsWrapper>
          <Button
            variant="primary"
            onClick={handleOnAccept}
            isLoading={!!isLoading}
          >
            {t("afba_modal.agree_button")}
          </Button>
          <Button variant="ghost" onClick={onCancel}>
            {t("afba_modal.cancel_button")}
          </Button>
        </ModalButtonsWrapper>
      }
    >
      <>
        <p>{t("afba_modal.content")}</p>
        <AfBAContent />
      </>
    </GenericBaseModal>
  );
};

export default AcceptAfBAModal;
