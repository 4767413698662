import * as React from "react";
import { forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, cx, type VariantProps } from "class-variance-authority";
import { Theme, useThemeContext } from "../../contexts/theme-context";

const buttonVariants = cva(
  "ui-button d-inline-flex align-items-center justify-content-center text-nowrap text-decoration-none border-1",
  {
    variants: {
      theme: {
        [Theme.PURPLE_RAIN]: "ui-button--purple-rain text-uppercase",
        [Theme.LAKEVIEW_BLUE]: "ui-button--lakeview-blue",
      },
      variant: {
        primary: "ui-button--primary",
        secondary: "ui-button--secondary",
        ghost: "ui-button--ghost",
        floating: "ui-button--floating typeface-h5",
      },
      size: {
        sm: "ui-button--small py-s6 gap-s8",
        md: "ui-button--medium py-s12 gap-s12",
        lg: "ui-button--large py-s18 gap-s12",
      },
      fullWidth: {
        full: "w-100",
        mobile: "w-100 w-md-auto",
        auto: "w-auto",
      },
      isLoading: {
        true: "ui-button--is-loading",
      },
    },
    compoundVariants: [
      {
        variant: ["primary", "secondary", "ghost"],
        className: "rounded-1",
      },
      {
        theme: Theme.PURPLE_RAIN,
        variant: ["primary", "secondary", "ghost"],
        size: "sm",
        className: "typeface-cta-small",
      },
      {
        theme: Theme.PURPLE_RAIN,
        variant: ["primary", "secondary", "ghost"],
        size: ["md", "lg"],
        className: "typeface-cta",
      },
      {
        size: ["sm", "md", "lg"],
        variant: "ghost",
        className: "px-s8",
      },
      {
        size: "sm",
        variant: ["primary", "secondary", "floating"],
        className: "px-s12",
      },
      {
        size: "md",
        variant: ["primary", "secondary", "floating"],
        className: "px-s20",
      },
      {
        size: "lg",
        variant: ["primary", "secondary", "floating"],
        className: "px-s24",
      },
    ],
    defaultVariants: {
      theme: Theme.PURPLE_RAIN,
      variant: "primary",
      size: "md",
      fullWidth: "auto",
      isLoading: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      theme,
      variant,
      size,
      isLoading,
      fullWidth,
      asChild = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const { theme: currentTheme } = useThemeContext();
    return (
      <Comp
        className={cx(
          buttonVariants({
            theme: theme || currentTheme,
            size,
            variant,
            isLoading,
            fullWidth,
            className,
          })
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
