import { translate as t } from "../helpers/i18n";
import SpinnerLoader from "../atomic/atoms/spinner-loader";

const Loader = () => (
  <div className="d-flex flex-column align-items-center gap-3 vh-100 justify-content-center bg-tan-100">
    <SpinnerLoader className="spinner-loader--dac" variant="primary" />
    <div className="typeface-body-large text-center">
      {t("global.helpers.wait_a_moment")}
    </div>
  </div>
);

export default Loader;
